$gnl-card-bg-color: #f5f5f5;
$gnl-card-color: rgba(0, 0, 0, 0.87);
$gnl-card-grey-color: #d1d1d1;
$gnl-card-title-color: #607d8b;
$color-light: rgba(0, 0, 0, 0.6);

.public-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin: 0;
}


.gnl-filter-container {
  margin-bottom: 1.75rem;
  margin-top: 1rem;


  .mdc-text-field {
    border-bottom: 2px solid #d1d1d1;
    font-size: 1.2rem !important;
    color: $gnl-card-color !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: $gnl-card-color !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: $gnl-card-color !important;
    font-size: 1.2rem;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #f5f5f5 !important;
    color: $gnl-card-color !important;
  }

  mat-form-field {
    width: 50% !important;
  }

}


.alternate-theme.gnl-custom-form-field {
  .mdc-text-field {
    border-bottom: 2px solid #d1d1d1;
    font-size: 1.1rem !important;
    color: $gnl-card-color !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: $gnl-card-color !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: $gnl-card-color !important;
    font-size: 1.1rem;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #f5f5f5 !important;
    color: $gnl-card-color !important;
  }

  mat-form-field {
    width: 50% !important;
  }

}


.public-footer {
  padding: 1rem 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #607d8b;
  color: #FFFFFF;
}


.gnl-small-column {
  width: 3rem !important;
  max-width: 3rem !important;
  min-width: 3rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-wrap: break-word;
}


.gnl-large-column {
  width: 30rem !important;
  max-width: 30rem !important;
  min-width: 30rem !important;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.mat-mdc-paginator {
  background: transparent !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: rgba(0, 0, 0, 0.87);
}


a {
  text-decoration: none !important;
}


a:visited {
  text-decoration: none !important;
}


.gnl-detail-link {
  cursor: pointer !important;
}

.gnl-detail-link:hover {
  color: $gnl-card-title-color !important;
}


.gnl-spinner {
  z-index: 1001 !important; /* Adjust this value according to your needs */
}

.gnl-all-button {
  font-size: 1.1rem;
  padding: 1.1rem !important;
}


@media (max-width: 599px) {
  .mat-cell,
  .mat-header-cell {
    font-size: 0.95rem !important;
  }

  .gnl-all-button {
    font-size: 0.95rem !important;
    padding: 0.95rem !important;
  }


  .gnl-large-column {
    width: auto !important;
    max-width: auto !important;
    min-width: auto !important;
  }

}

/*
Ganahl Lumber custom theme
This file imports the GNL custom theme styles, including color palettes, typography, and components.
*/
@import 'assets/scss/_gnl-theme.scss';

//Custom imports
@import 'assets/scss/public';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$gnl-custom-typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(40px, 1.2, 700, 'Montserrat', normal),
  $headline-2: mat.define-typography-level(36px, 1.2, 400, 'Montserrat', normal),
  $headline-3: mat.define-typography-level(24px, 1.2, 700, 'Montserrat', normal),
  $headline-4: mat.define-typography-level(30px, 1.2, 400, 'Open Sans', normal),
  $subtitle-1: mat.define-typography-level(16px, 1.5, 700, 'Open Sans', normal),
  $subtitle-2: mat.define-typography-level(18px, 1.5, 400, 'Open Sans', normal),
  $body-1: mat.define-typography-level(16px, 1.5, 400, 'Open Sans', normal),
  $body-2: mat.define-typography-level(14px, 1.5, 400, 'Open Sans', normal),
  $caption: mat.define-typography-level(12px, 1.5, 400, 'Open Sans', normal),
  $button: mat.define-typography-level(14px, 1.2, 700, 'Montserrat', normal),
);

@include mat.typography-hierarchy($gnl-custom-typography);


$gnl-accent: mat.define-palette(mat.$blue-grey-palette, 500);
$gnl-contrast: mat.define-palette(mat.$blue-palette, 700);
$gnl-warn: mat.define-palette(mat.$red-palette);
$gnl-background: mat.define-palette(mat.$grey-palette, 50);
$gnl-surface: mat.define-palette(mat.$grey-palette, 100);

$gnl-primary: mat.define-palette((
  50: #f8e4e4,
  100: #e1b6b4,
  200: #ca8782,
  300: #b35750,
  400: #a34039,
  500: #bf302b, // Primary color
  600: #8f1d1b,
  700: #6e1514,
  800: #4c0d0c,
  900: #2b0504,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
));


$gnl-dark-theme: mat.define-dark-theme((
  color: (
    primary: $gnl-primary,
    accent: $gnl-accent,
    warn: $gnl-warn,
    background: $gnl-background,
    surface: $gnl-surface,
  ),
  density: 0
));

// Include theme styles for core and each component used in your app
@include mat.all-component-themes($gnl-dark-theme);


.alternate-theme {
  // Define custom theme using the palettes
  $gnl-light-theme: mat.define-light-theme((
    color: (
      primary: $gnl-primary,
      accent: $gnl-accent,
      warn: $gnl-warn,
      background: $gnl-background,
      surface: $gnl-surface,
    ),
    density: -2,
  ));
  @include mat.all-component-colors($gnl-light-theme);
}
